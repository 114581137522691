import { useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import { Box } from '@mui/material';
import { TextField as TextFieldRFF } from 'mui-rff';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { countriesSelectors } from '../../redux/countriesSlice';
import AddressLookup from '../AddressLookup';
import { formatCoordinates, transformAddressValues } from '../../utils/address';
import CountriesSelect from '../CountriesSelect';
import { fastFind } from '../../apis/address';

const AddressDetailsForm = ({
  values = {},
  sx,
  disabled,
  config: {
    fields,
    inputSize = 'medium',
    idPrefix,
    availableCountryCodes = [],
  },
}) => {
  const form = useForm();
  const countryISO = get(values, fields.country.name);
  const addressLookupKey = get(values, fields.addressId.name);
  const countries = useSelector(countriesSelectors.getCountries);
  const availableCountries = availableCountryCodes.map(countryCode =>
    countries.find(({ countryKey }) => countryCode === countryKey)
  );
  const { input: countrySelectInput } = useField(fields.country.name);

  const shouldAutoFill = fullAddress => {
    // User selected new address from the search lookup
    if (fullAddress.isOptionSelectedManually) {
      return true;
    }

    // Postcode change
    if (get(values, fields.postcode.name) !== fullAddress.Postcode) {
      return true;
    }

    return false;
  };

  const clearAddressDetailsForm = () => {
    form.batch(() => {
      form.change(fields.addressId.name, '');
      form.change(fields.coordinates.name, null);
      form.change(fields.street.name, '');
      form.change(fields.locality.name, '');
      form.change(fields.town.name, '');
      form.change(fields.county.name, '');
      form.change(fields.postcode.name, '');
      form.change(fields.organisation.name, '');
    });
  };

  // TODO
  const onAddressLookupChange = fullAddress => {
    if (!shouldAutoFill(fullAddress)) {
      return;
    }

    const { addressLine1, addressLine2 } = transformAddressValues({
      street: fullAddress.Street,
      locality: fullAddress.Locality,
      property: fullAddress.Property,
    });

    form.batch(() => {
      form.change(fields.addressId.name, fullAddress.Key);
      form.change(fields.coordinates.name, formatCoordinates(fullAddress));
      form.change(fields.street.name, addressLine1 || '');
      form.change(fields.locality.name, addressLine2 || '');
      form.change(fields.town.name, fullAddress.Town || '');
      form.change(fields.county.name, fullAddress.PostalCounty || '');
      form.change(fields.postcode.name, fullAddress.Postcode);
      form.change(fields.organisation.name, fullAddress.Organisation);
    });
  };

  // TODO
  const findAndUpdateAddressLookupKey = async (
    lookup,
    countryCode = countryISO
  ) => {
    const { Item: results = [] } = await fastFind({
      lookup,
      countryISO: countryCode,
    });
    if (results.length > 0) {
      // Update address lookup key if it is found
      // debouncedOnChange(
      //   { ...formValues, [FIELDS.ADDRESS_KEY.KEY]: results.Item[0].Key },
      //   true
      // );
    }
  };

  useEffect(() => {
    !addressLookupKey &&
      values.postcode &&
      findAndUpdateAddressLookupKey(values.postcode);
  }, []);

  // Remove country select field since it have a special logic
  const regularFields = Object.keys(fields).filter(
    field => field !== 'country'
  );

  const onKeyDownHandler = event => {
    const fieldName = event.target.name;

    // User manual input additional handling
    const userInputHandlingMap = {
      [fields.postcode.name]: () => {
        // Cleanup Address input on manual postcode change
        form.change(fields.addressId.name, '');
      },
    };

    userInputHandlingMap[fieldName]?.(event);
  };

  const handleCountryChange = event => {
    const { value: countryValue } = event.target;
    if (countryValue !== countryISO) {
      clearAddressDetailsForm();
    }
    countrySelectInput.onChange(countryValue);
  };
  return (
    <Box
      noValidate
      autoComplete='off'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        ...sx,
      }}
    >
      <CountriesSelect
        component={TextFieldRFF}
        countries={availableCountries.length ? availableCountries : countries}
        blurOnSelect
        select
        label={fields.country.label}
        variant='outlined'
        required={fields.country.required}
        disabled={!countries.length || disabled || fields.country.disabled}
        name={fields.country.name}
        size={inputSize}
        onChange={handleCountryChange}
        inputProps={{
          'data-testid': `${idPrefix}-country-select`,
        }}
      />

      <AddressLookup
        onChange={onAddressLookupChange}
        variant='outlined'
        addressKey={addressLookupKey}
        countryISO={countryISO}
        disabled={disabled}
        inputSize={inputSize}
        autocompleteInputId={`${idPrefix}-address-lookup`}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {regularFields.map(field => (
          <TextFieldRFF
            key={fields[field].name}
            label={fields[field].label}
            name={fields[field].name}
            onKeyDown={onKeyDownHandler}
            size={inputSize}
            disabled={disabled || fields[field].disabled}
            required={fields[field].required}
            inputProps={{
              'data-testid': `${idPrefix}-${field}`,
            }}
            {...fields[field].props}
          />
        ))}
      </Box>
    </Box>
  );
};

const PropTypeFieldShape = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
});

AddressDetailsForm.propTypes = {
  values: PropTypes.object.isRequired,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  config: PropTypes.shape({
    fields: PropTypes.shape({
      // addressId: PropTypeFieldShape,
      country: PropTypeFieldShape,
      organisation: PropTypeFieldShape,
      street: PropTypeFieldShape,
      locality: PropTypeFieldShape,
      town: PropTypeFieldShape,
      county: PropTypeFieldShape,
    }),
    inputSize: PropTypes.string,
    hasActionButtons: PropTypes.bool,
    idPrefix: PropTypes.string,
  }),
};

export default AddressDetailsForm;
