import { Route, Routes } from 'react-router';
import Home from '../pages/Home/Home';
import SignIn from '../pages/Auth/SignIn';
import MapSandbox from '../pages/MapSandbox';
import Order from '../pages/Order';
import OrderSummary from '../pages/OrderSummary';
import Quotes from '../pages/Quotes';
import DevRoutes from '../components/DevRoutes';
import Playground from '../pages/Playground';
import ShopFinder from '../pages/ShopFinder';
import Basket from '../pages/Basket';
import UserPreferences from '../pages/UserPreferences';
import AddressesSection from '../pages/UserPreferences/AddressesSection';
import {
  OrdersSection,
  OrderView,
} from '../pages/UserPreferences/OrdersSection';
import Track from '../pages/Track';
import { useAuth } from '../features/Auth/Context';

import {
  ACCOUNT,
  ADDRESSES,
  ORDERS,
  ORDER_VIEW,
  PICKUP_FINDER,
  PROFILE,
  SHIPMENT,
  DONT_HAVE_ACCESS,
  HOME,
  TRACK,
  BASKET,
  CONFIRM,
  CONFIRM_COLLECTION,
  CONFIRM_LABEL,
  DEV,
  DEV_PLAYGROUND,
  EMAIL,
  MAP_SAND_BOX,
  ORDER,
  ORDER_SUMMARY,
  QUOTES,
  SIGN_IN,
  SIGN_UP,
} from '../constants/routes';
import { AccountSection } from '../pages/UserPreferences/AccountSection';
import OverviewSection from '../pages/UserPreferences/OverviewSection/OverviewSection';
import Shipment from '../pages/Shipment';
import DropOff from '../pages/Shipment/DropOff';
import DropOffLabel from '../pages/Shipment/DropOffLabel';
import Collection from '../pages/Shipment/Collection';
import {
  ErrorBoundary,
  useErrorBoundaryConfigurations,
} from '../features/ErrorBoundary';
import SignUp from '../pages/Auth/SignUp';
import ProtectedRoute from './ProtectedRoute';

function AppRoutes() {
  const { currentSession } = useAuth();
  const { dontHaveAccess } = useErrorBoundaryConfigurations();

  return (
    <Routes>
      <Route path={HOME} element={<Home />} />
      <Route path={TRACK} element={<Track />} />
      <Route path={BASKET} element={<Basket />} />
      <Route path={ORDER_SUMMARY} element={<OrderSummary />} />
      <Route path={SHIPMENT} element={<Shipment />} />
      {/* start - remove */}
      <Route path={CONFIRM} element={<DropOff />} />
      <Route path={CONFIRM_LABEL} element={<DropOffLabel />} />
      <Route path={CONFIRM_COLLECTION} element={<Collection />} />
      {/* end - remove */}
      <Route
        path={`${PROFILE}/*`}
        element={
          <ProtectedRoute
            isEnabled={!!currentSession?.uid}
            redirectTo={DONT_HAVE_ACCESS}
            element={<UserPreferences />}
          />
        }
      >
        <Route index element={<OverviewSection />} />
        <Route path={ACCOUNT} element={<AccountSection />} />
        <Route path={ADDRESSES} element={<AddressesSection />} />
        <Route path={ORDER_VIEW} element={<OrderView />} />
        <Route path={ORDERS} element={<OrdersSection />} />
      </Route>
      <Route path={SIGN_IN}>
        <Route path={EMAIL} element={<SignIn />} />
      </Route>
      <Route path={SIGN_UP} element={<SignUp />} />
      <Route path={MAP_SAND_BOX} element={<MapSandbox />} />
      <Route path={ORDER} element={<Order />} />
      <Route path={QUOTES} element={<Quotes />} />
      <Route path={PICKUP_FINDER} element={<ShopFinder />} />
      <Route path={DEV} element={<DevRoutes />}>
        <Route path={DEV_PLAYGROUND} element={<Playground />} />
      </Route>
      <Route
        path={DONT_HAVE_ACCESS}
        element={<ErrorBoundary config={dontHaveAccess()} />}
      />
      <Route path='*' element={<ErrorBoundary />} />
    </Routes>
  );
}

export default AppRoutes;
